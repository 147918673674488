import React from 'react';

const View = () => {
     return (
          <div style={{ padding: '200px 100px 200px' }}>
               https://dreamwave.tech/custom-projects?ref=activetheory.ghost.io <br/>
               https://ambushsilverfctry.io/?ref=activetheory.ghost.io <br/>
               https://atlab.io/fabric <br/>
               https://magicwindow.io/ <br/>
               https://activetheory.net/ <br/>
          </div>
     );
}

export default View;
