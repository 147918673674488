import * as THREE from 'three';
import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Environment, Float, OrbitControls, Sparkles } from '@react-three/drei';
import { LayerMaterial, Noise, } from 'lamina';

function Striplight(props) {
    return (
        <mesh {...props}>
            <boxGeometry />
            <meshBasicMaterial color="white" />
        </mesh>
    );
}

function AnimatedMesh() {
    const meshRef = useRef();
    const [targetColor, setTargetColor] = useState(new THREE.Color('red'));
    const [p, setP] = useState(2); // Initial values for p and q
    const [q, setQ] = useState(1);

    // Cycle color and geometry values
    useEffect(() => {
        const colors = ['red', '#e3bc57', 'blue', 'black', '#2e1959', 'green' ];
        let colorIndex = 0;

        const colorChangeInterval = setInterval(() => {
            colorIndex = (colorIndex + 1) % colors.length;
            setTargetColor(new THREE.Color(colors[colorIndex]));

            // Cycle `p` and `q` values
            setP(prev => (prev < 9 ? prev + 1 : 1)); // p cycles between 1 and 9
            setQ(prev => (prev < 900 ? prev + 1 : 1)); // q cycles between 1 and 400

        }, 1000); // Change target color and p, q every 1 second


        return () => clearInterval(colorChangeInterval);
    }, []);

    useFrame((_, delta) => {
        if (meshRef.current) {
            // Smoothly transition to the target color
            meshRef.current.material.color.lerp(targetColor, delta * 3);
        }
    });

    return (
        <Float position={[0, 2.20, 0]} speed={22} scale={0.300} rotationIntensity={1} floatIntensity={0.2}>
            <mesh ref={meshRef}>
                <boxGeometry args={[2, 2, 2]} />
                {/* <torusKnotGeometry args={[1, 0.30, 350, 9, p, q]} /> */}
                <torusKnotGeometry args={[1, 0.30, 400, 99, p * 10, q]} />
                <meshStandardMaterial color="red" roughness={0.1} metalness={0.925} />
            </mesh>
        </Float>
    );
}


export default function CanvaSection(props) {

    return (
        <div className='__homeCanvas'>
            <Canvas dpr={[1, 1]} camera={{ position: [0, 0, 1] }}>
                <OrbitControls minPolarAngle={Math.PI / 1.8} maxPolarAngle={Math.PI / 1.8} enableZoom={false} />
                {/* {props.scrolled ? '' : <Status position={[0, 2, -11]} demotxt={"#thedc"} />} */}

                <Sparkles color={'white'} scale={0.8} speed={1.4} count={100} />
                <group position={[0, -2.2, 0]}>
                    <AnimatedMesh />
                </group>

                {/* <Stars radius={100} depth={50} count={5000} factor={4} saturation={1} fade  color={'red'}/> */}

                <Environment resolution={64}>
                    <Striplight position={[10, 2, 0]} scale={[1, 3, 10]} />
                    <Striplight position={[-10, 2, 0]} scale={[1, 3, 10]} />
                    <mesh scale={100}>
                        <sphereGeometry args={[1, 64, 64]} />
                        <LayerMaterial side={THREE.BackSide}>
                            <Noise mapping="local" type="box" scale={1} />
                        </LayerMaterial>

                    </mesh>
                </Environment>
                <ambientLight intensity={1} />


            </Canvas>
        </div>
    );
}
