import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logo, title } from '../Config/Config';
import { OpenInNew, PivotTableChart } from '@mui/icons-material';
import { Cookies, getCookie } from 'typescript-cookie';
import { HeaderItem, HeaderItemtools } from '../AllContent/Content';
import toasted from '../Config/toast';
import { Offcanvas } from 'react-bootstrap';


const Header = () => {
     const navigate = useNavigate();
     const location = useLocation();
     const auth = getCookie("auth");



     const handleLogout = () => {
          Cookies.remove("auth");
          toasted.error("You have been logged out.");
          navigate("/login");
     };


     useEffect(() => {
          const handleLogout = () => {
               Cookies.remove("auth");
               toasted.error("You have been logged out.");
               navigate("/login");
          };

          const startLogoutTimer = () => {
               setTimeout(() => {
                    handleLogout();
               }, 5 * 60 * 60 * 1000); // 5 hours
          };

          startLogoutTimer();

          return () => {
               // Clear timeout if necessary
          };
     }, [navigate]); // Add 'navigate' to the dependency array



     const [show, setShow] = useState(false);
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);

     // const listRef = useRef<HTMLUListElement>(null);

     // const handleScroll = (event: React.WheelEvent) => {
     //      if (listRef.current) {
     //           // Vertical scroll ko horizontal scroll me convert kar rahe hain
     //           listRef.current.scrollLeft += event.deltaY;
     //      }
     // };


     return (
          <>
           <div className='grain'></div>
               <header id='header-banner' >
                    <div className='__container' >
                         <Link to="/" className='_logo'>
                              <img src={logo} alt={title + "logo"} />
                         </Link>
                         <button type='button' className='btn-toggle'> <PivotTableChart /></button>

                         <div className='__list'>
                              <ul className='nav'>

                                   {HeaderItem.map((item, index) => (
                                        <li className='nav-item ' key={index}>
                                             {item.button ?
                                                  auth ?
                                                       <button type='button' className='nav-button' onClick={handleLogout}>Logout</button>
                                                       :
                                                       <Link to={item.path} className="nav-button "> {item.label} </Link>
                                                  :
                                                  item.inout ?
                                                       <Link to={auth ? '/view' : item.path} className="nav-link"> {auth ? "Overview" : item.label} </Link>
                                                       :
                                                       item.exLink ?
                                                            <Link to={'#'} onClick={handleShow} className="nav-link"> {item.label} </Link>
                                                            :
                                                            <Link to={item.path} className="nav-link"> {item.label} </Link>

                                             }
                                        </li>
                                   ))}
                              </ul>

                         </div>
                    </div>
               </header>



               <Offcanvas show={show} onHide={handleClose} placement="start" scroll={false} backdrop={true} >
                    <Offcanvas.Header closeButton>
                         <Offcanvas.Title>Tools</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                         <ul>
                              {HeaderItemtools.map((item, index) => {
                                   return (
                                        <li className='nav-item' key={index}>
                                             <Link to={item.path} className='nav-link' target='_blank'>
                                                 
                                                  <p> <span> {item.icon} </span> {item.label}</p>
                                                  <OpenInNew className='__extrnal_link' /></Link>
                                        </li>
                                   )
                              })}
                         </ul>

                         {HeaderItemtools.length > 5 && (
                              <div className='__footer-off'>
                                   <h5> Scroll Down seen More tools  </h5>
                              </div>
                         )}
                    </Offcanvas.Body>
               </Offcanvas>
          </>

     );
}

export default Header;
