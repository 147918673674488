import { AdsClick, AutoMode, Code, ColorLens, Devices, Facebook, GitHub, Group, Icecream, Instagram, LinkedIn, SettingsInputSvideo, ThumbUpAlt } from "@mui/icons-material";
import { instaLink, linkedinLink, title } from "../Config/Config";



//todo  Header Item , Link and  Buttons

export const HeaderItem = [
     { path: '/about', label: 'About', inout: true },
     { path: '/websites', label: 'Websites' },
     { path: '/contact-us', label: 'Contact us' },
     { path: '#', label: 'Tools', exLink: true },
     { path: '/login', label: 'Team Members', button: true },
];

export const HeaderItemtools = [
     { path: '/colos', label: 'Explore Vibrant Color ', icon: <SettingsInputSvideo /> },
     { path: '/gradients', label: 'Explore Vibrant Gradients', icon: <ColorLens /> },
     { path: 'https://thedchere.github.io/tools/#svg-to-code', label: 'Svg to Code', icon: <SettingsInputSvideo /> },
     { path: 'https://thedchere.github.io/tools/#svg-circle-progress', label: 'SVG Circle Progress', icon: <AutoMode /> },
     { path: 'https://thedchere.github.io/tools/#responsive-tester', label: 'Responsive Design Tester', icon: <Devices /> },
];

export const socialMedia = [
     {
          name: 'facebook',
          icon: <Facebook />,
          link: "https://www.facebook.com/",
     },
     {
          name: 'instagram',
          icon: <Instagram />,
          link: instaLink,
     },
     {
          name: 'Linkedin',
          icon: <LinkedIn />,
          link: linkedinLink,
     },
     {
          name: 'snapchat',
          icon: <Icecream />,
          link: "https://snapchat.com/t/RzcXS8BN",
     },
     {
          name: 'Github',
          icon: <GitHub />,
          link: "https://github.com/thedchere",
     },
     {
          name: 'Codepen',
          icon: <Code />,
          link: "https://codepen.io/thedc__",
     },



]




//todo Footer Content and Medias With Name and Url

export const footerData = {

     text: {
          heading: `${title} is a global design studio.`,
          paragraph: "We create design and brands that help companies grow.",
          copyright: `Copyright © ${new Date().getFullYear()} ${title}. All Rights Reserved.`,
     },

     socialMedia: [
          {
               name: 'facebook',
               icon: <Facebook />,
               link: "https://www.facebook.com/",
          },
          {
               name: 'Codepen',
               icon: <Code />,
               link: "https://codepen.io/thedc__",
          },
          {
               name: 'Linkedin',
               icon: <LinkedIn />,
               link: "https://www.linkedin.com/in/devsunilsharma?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
          },
          {
               name: 'instagram',
               icon: <Instagram />,
               link: "https://instagram.com/thedc__",
          }
     ]
};



//! Index Page 

export const Indexdata = {
     Banner: {
          Heading: {
               HeadActive: `${title} Tech`,
               Pragraph: "Branding & Design Experts"
          },
          pragrph: "Our long experience and design sagacity help us spot essential things while selecting a design that business owners often miss.",
          Button: {
               BtnText: 'start free',
               btnUrl: '/login',
               authUrl: "/view",
          }
     },

     // about section 

     about: {
          head: {
               heading: "Hey, I'm Sunil Sharma",
               secondName: title,
               subheading: "Web Designer"
          },

          cardheding: "Obsessed with creating timeless digital experiences with react.",
          cardPragraph: `welcome to my world. I love building beautiful, timeless <br/>  websites & digital experiences with react.`,
     },

     // brand section 

     Brand: {
          head: {
               heading: "creative",
               subHeading: "Unleashing Creativity, One Project at a Time."
          },
          CretiveCard: [
               {
                    id: "card-1",
                    icon: <AdsClick />,
                    heading: "Innovative Web Design",
                    pragraph: "Bringing your ideas to life with cutting-edge design that captivates and engages. From concept to creation, we craft websites that stand out. "
               },
               {
                    id: "card-2",
                    icon: <ThumbUpAlt />,
                    heading: "Seamless User Experience",
                    pragraph: "Design isn’t just about looks; it’s about how it works. We ensure every interaction on your site is intuitive, smooth, and satisfying for your users.",
                    center: true
               },
               {
                    id: "card-3",
                    icon: <Group />,
                    heading: "Tailored Solutions",
                    pragraph: "Every brand is unique, and so are our designs. We provide customized web solutions that reflect your brand’s personality and resonate with your audience."
               }
          ]

     },

     // Question section 

     Question: {
          head: {
               heading: "expert",
               subHeading: "The Minds Behind the Magic"

          },
          Faqwrap: [
               {
                    key: "01",
                    headingfaq: "Strategic Visionaries",
                    pragrphfaq: "Our team of experts goes beyond design. We craft strategies that align with your business goals, ensuring your website not only looks great but also drives results.",
               },
               {
                    key: "02",
                    headingfaq: "Technical Masters",
                    pragrphfaq: "From front-end to back-end development, our technical experts bring precision and innovation to every project, building websites that are both robust and scalable.",
               },
               {
                    key: "03",
                    headingfaq: "Creative Innovators",
                    pragrphfaq: "Creativity is at the heart of what we do. Our design experts push the boundaries of web design, delivering unique and visually stunning experiences tailored to your brand.",
               },
               {
                    key: "04",
                    headingfaq: "Brand Storytellers",
                    pragrphfaq: "We help you tell your brand’s story through a cohesive design and content strategy that resonates with your target audience.",
               }
          ]
     },

     // Interesting section 

     Interesting: {
          head: {
               heading: "Interesting",
               SubHeading: "Portfolio",
               pragraph: "Interesting facts figure We combine human empathy and intelligent data to provide the",
          },
     }
} 