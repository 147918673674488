import React from 'react'
import { Link } from 'react-router-dom'
import figma_to from '../../Assets/Image/figmato.webp'
import landingto from '../../Assets/Image/landingpage.webp'
import custompageto from '../../Assets/Image/custom-project.webp'

const Services = () => {
     return (
          <section className='__services'>
               <div className='__wapper'>
                    <div className='__heading-sec'>
                         <h1><span>Services</span>   </h1>
                    </div>
                    <div className='row'>

                    <div className='col-lg-6'>
                              <div className='__card --list-card'>
                                   <h4>On-going monthly support</h4>
                                   <p>Unlimited Design &/or Webflow requests, delivered one at a time.</p>

                                   <div className='__list'>
                                        <ul>
                                             <li>Unlimited requests</li>
                                             <li>Average 24-48 h delivery</li>
                                             <li>Unlimited revisions</li>
                                             <li>Custom design</li>
                                             <li>Async collaboration</li>
                                             <li>Fixed monthly rate</li>
                                        </ul>
                                   </div>

                                   <div className='__in-btn'>
                                        <Link to="/contact-us" className='btn'> Get in Touch </Link>
                                   </div>
                              </div>
                         </div>


                         <div className='col-lg-6'>
                              <div className='__card '>
                                   <h4>Custom project</h4>
                                   <p>I have extensive branding capabilities within my collaborators. If your project is more custom, let's chat about it.</p>

                                   <div className='__img'>
                                        <img src={custompageto} alt='figma to code' />
                                   </div>

                                   <div className='__in-btn'>
                                        <Link to="/contact-us" className='btn'> Get in Touch </Link>
                                   </div>
                              </div>
                         </div>


                         <div className='col-lg-6'>
                              <div className='__card'>
                                   <h4>5-days landing page</h4>
                                   <p>Do you need a custom one-pager or a landing page? I'm here for you.</p>

                                   <div className='__img'>
                                        <img src={landingto} alt='figma to code' />
                                   </div>

                                   <div className='__in-btn'>
                                        <Link to="/contact-us" className='btn'> Get in Touch </Link>
                                   </div>
                              </div>
                         </div>


                         <div className='col-lg-6'>
                              <div className='__card'>
                                   <h4>Figma-to-Webflow</h4>
                                   <p>Let's translate your design to reality Developing in Webflow. Fast, scalable, and pixel perfect.</p>

                                   <div className='__img'>
                                        <img src={figma_to} alt='figma to code' />
                                   </div>

                                   <div className='__in-btn'>
                                        <Link to="/contact-us" className='btn'> Get in Touch </Link>
                                   </div>
                              </div>
                         </div>

                         
                    </div>
               </div>
          </section>
     )
}

export default Services