import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import __img_me from '../../Assets/Image/meThedc.jpeg';
import __img_india from '../../Assets/Image/india.webp';
import __img_working from '../../Assets/Image/working.webp';
import html__svg from '../../Assets/icon/html.svg'
import css__svg from '../../Assets/icon/css.svg'
import bootstrap__svg from '../../Assets/icon/bootstrap.svg'
import sass__svg from '../../Assets/icon/sass.svg'
import tailwind__svg from '../../Assets/icon/tailwind.svg'
import javascript__svg from '../../Assets/icon/javascript.svg'
import react__svg from '../../Assets/icon/react.svg'
import npmjs__svg from '../../Assets/icon/npmjs.svg'
import threejs__svg from '../../Assets/icon/threejs.svg'
import photoshop__svg from '../../Assets/icon/photoshop.svg'
import figma__svg from '../../Assets/icon/figma.svg'
import spline__svg from '../../Assets/icon/spline.svg'
import webflow__svg from '../../Assets/icon/webflow.svg'
import github__svg from '../../Assets/icon/github.svg'
import chatgpt__svg from '../../Assets/icon/chatgpt.svg'
import canvas__svg from '../../Assets/icon/canvas.svg'

const About = (props: any) => {
     const headingRef = useRef<HTMLDivElement>(null);

     const rotateToMouse = (e: MouseEvent, imgElement: HTMLImageElement) => {
          const bounds = imgElement.getBoundingClientRect();
          const mouseX = e.clientX;
          const mouseY = e.clientY;
          const leftX = mouseX - bounds.x;
          const topY = mouseY - bounds.y;
          const center = {
               x: leftX - bounds.width / 2,
               y: topY - bounds.height / 2,
          };
          const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

          imgElement.style.transform = `
      scale3d(4, 4, 4)
      rotate3d(
        ${center.y / 100},
        ${center.x / 100},
        0,
        ${Math.log(distance) * 4}deg
      )
    `;
          imgElement.style.filter = 'brightness(1.2) contrast(1.1)';
     };

     const resetImage = (imgElement: HTMLImageElement) => {
          imgElement.style.transform = '';
          imgElement.style.filter = '';
     };

     useEffect(() => {
          if (!headingRef.current) return;

          const images = headingRef.current.querySelectorAll('img');
          images.forEach((img) => {
               const handleMouseMove = (e: MouseEvent) => rotateToMouse(e, img as HTMLImageElement);
               const handleMouseLeave = () => resetImage(img as HTMLImageElement);

               img.addEventListener('mousemove', handleMouseMove);
               img.addEventListener('mouseleave', handleMouseLeave);

               return () => {
                    img.removeEventListener('mousemove', handleMouseMove);
                    img.removeEventListener('mouseleave', handleMouseLeave);
               };
          });
     }, []);



     const skill = [
          {
               name: 'HTML5',
               Icon: html__svg,
               link: 'https://html5.org/'
          },
          {
               name: 'CSS3',
               Icon: css__svg,
               link: 'https://css3.com/'
               
          },
          {
               name: 'Bootstrap',
               Icon: bootstrap__svg,
               link: 'https://getbootstrap.com/'
          },
          {
               name: 'SCSS',
               Icon: sass__svg,
               link: 'https://sass-lang.com/'
          },
          {
               name: 'tailwind',
               Icon: tailwind__svg,
               link: 'https://tailwindcss.com/'
          },
          {
               name: 'Javascript',
               Icon: javascript__svg,
               link: 'https://www.javascript.com/'
          },
          {
               name: 'React js',
               Icon: react__svg,
               link: 'https://react.dev/'
          },
          {
               name: 'NPM',
               Icon: npmjs__svg,
               link: 'https://www.npmjs.com/'
          },
          {
               name: 'React Three Fiber',
               Icon: threejs__svg,
               link: 'https://r3f.docs.pmnd.rs/'
          },
          {
               name: 'canvas',
               Icon: canvas__svg,
               link: 'https://www.canva.com/'
          },
          {
               name: 'Photoshop',
               Icon: photoshop__svg,
               link: 'https://www.photopea.com/'
          },
          {
               name: 'Figma',
               Icon: figma__svg,
               link: 'https://www.figma.com/'
          },
          {
               name: 'Spline',
               Icon: spline__svg,
               link: 'https://spline.design/'
          },
          {
               name: 'Webflow',
               Icon: webflow__svg,
               link: 'https://webflow.com/'
          },
          {
               name: 'Github',
               Icon: github__svg,
               link: 'https://github.com'
          },
          {
               name: 'chat-gpt',
               Icon: chatgpt__svg,
               link: 'https://chatgpt.com/'
          },
     ]

     return (
          <section className="__abouts">
               <div className="__wapper">
                    <div className="__about-heading" ref={headingRef}>
                         I’m <img src={__img_me} alt="my-picture" /> Sunil Sharma, <br/>
                         indian <img src={__img_india} alt="my-picture" /> web designer working  on gni soft
                         solutions <img src={__img_working} alt="my-picture" /> or Webflow experiences
                    </div>


                    <div className='__skill'>
                         <div className='row'>
                              {skill.map((val, index) => (
                                   <div className='col-md-2 col-2' key={index}>
                                        <div className='__item' >
                                             <Link to={val.link} target='_blank'  className='stretched-link'>
                                                  <div className='__icon'>
                                                       <img src={val.Icon} alt={val.name} />
                                                  </div>
                                             </Link>
                                        </div>
                                   </div>
                              ))}
                         </div>

                    </div>
               </div>
          </section>
     );
};

export default About;
