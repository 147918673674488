import React from 'react';
import { logo } from '../Config/Config';
import { Link } from 'react-router-dom';
import { footerData, socialMedia } from '../AllContent/Content';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';



const Footer = () => {

     // useEffect(() => {
     //      const disableRightClick = (event: MouseEvent) => {
     //        event.preventDefault();
     //      };

     //      document.addEventListener('contextmenu', disableRightClick);


     //      return () => {
     //        document.removeEventListener('contextmenu', disableRightClick);
     //      };
     //    }, []);



     return (

          <ParallaxProvider>


               <Parallax speed={-20}>
                    <footer>

                         <div className='__container'>
                              <div className='__dc-footer'>
                                   <div className='row'>
                                        <div className='col-12'>
                                             <div className='__txt'>
                                                  <Parallax speed={-2}>
                                                       <Link to="/" className='logo'>
                                                            <img src={logo} alt='logo' />
                                                       </Link>
                                                       <h5>{footerData.text.heading}</h5>
                                                       <p> {footerData.text.paragraph}</p>
                                                       <Parallax speed={-2}>
                                                            <Link to="/login" className='btn-common'> Get Start </Link>
                                                       </Parallax>
                                                  </Parallax>
                                             </div>
                                        </div>

                                        <div className='col-12'>
                                             <div className='__links'>
                                                  <Parallax speed={-2}>
                                                       <ul className='nav'>
                                                            {socialMedia.map((item, index) => (
                                                                 <li className='nav-item' key={index}>
                                                                      <Link className='nav-link' target='_blank' to={item.link}>
                                                                           {item.icon}
                                                                           <span>{item.name} </span>
                                                                      </Link>
                                                                 </li>
                                                            ))}
                                                       </ul>
                                                  </Parallax>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className='copy-right'>
                                   <p> {footerData.text.copyright} </p>
                              </div>
                         </div>
                    </footer>
               </Parallax>
          </ParallaxProvider>
     );
}

export default Footer;
