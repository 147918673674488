import React, { useState } from 'react';

const Download: React.FC = () => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        if (!url) return;
        setLoading(true);

        try {
            const response = await fetch(url, { method: 'GET' });
            const text = await response.text();

            const blob = new Blob([text], { type: 'text/html' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'website.html';
            link.click();
        } catch (error) {
            console.error('Error fetching website:', error);
            alert('Error downloading the website');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '200px 20px 200px' }}>
            <h2>Simple Static HTML Downloader</h2>
            <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter website URL"
                style={{ width: '300px', marginRight: '10px' }}
            />
            <button onClick={handleDownload} disabled={loading}>
                {loading ? 'Downloading...' : 'Download HTML'}
            </button>
        </div>
    );
};

export default Download;
