import { nanoid } from 'nanoid';


export const initialGradients = [
     {
          id: nanoid(),
          type: 'linear-gradient',
          direction: 'to top',
          colors: ['#4158D0', '#C850C0', '#FFCC70'],
     },
     {
          id: nanoid(),
          type: 'linear-gradient',
          direction: 'to top',
          colors: ['#0093E9 0%', '#80D0C7 100%']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#08AEEA 0%', '#2AF598 100%']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(110, 231, 183) ', 'rgb(59, 130, 246)', 'rgb(147, 51, 234)']
     },

     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(236, 72, 153)', 'rgb(239, 68, 68)', 'rgb(245, 158, 11)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#21D4FD 0%', '#B721FF 100%']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#FFE53B 0%', '#FF2525 74%']
     },
     {
          id: nanoid(),
          direction: 'to bottom',
          type: 'linear-gradient',
          colors: [' #d5ddf8', '#ffffff 57%']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(185, 70, 108)', 'rgb(227, 181, 196)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(255, 128, 0)', 'rgb(255, 179, 102)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(253, 220, 53)', 'rgb(254, 237, 154)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(200, 223, 83)', 'rgb(227, 239, 169)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(67, 172, 32)', 'rgb(118, 223, 83)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(32, 172, 172)', 'rgb(83, 223, 223)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(83, 165, 223)', 'rgb(169, 210, 239)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(57, 81, 198)', 'rgb(136, 150, 221)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(69, 34, 119)', 'rgb(144, 97, 209)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(226, 29, 209)', 'rgb(243, 165, 237)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(232, 74, 140)', 'rgb(243, 165, 197)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(29, 184, 195)', 'rgb(245, 112, 174)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(255, 91, 87)', 'rgb(170, 228, 215)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(254, 190, 88)', 'rgb(222, 43, 174)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(79, 191, 201)', 'rgb(224, 56, 56)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(31, 137, 219)', 'rgb(244, 42, 139)']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#FFD439 0%', '#FF7A00 100%']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['red', 'blue']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#7CF7FF 0%', '#4B73FF 100%']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#FFED46 0%', '#FF7EC7 100%']
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#EAEAEA 0%', '#8B8B8B 100%']
     },
     {
          id: nanoid(),
          direction: '65% 100% at 50% 0% ',
          type: 'radial-gradient',
          colors: ['#00FF94 0%', 'rgba(0, 255, 148, 0.25)'],
     },

     // 31 to 56 missing data 

     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#FFDC99 0%', '#FF62C0 100%'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#DDE4FF 0%', '#8DA2EE 100%'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#24CFC5 0%', '#001C63 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#FF3F3F 0%', '#063CFF 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#5D85A6 0%', '#0E2C5E 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#DEB5FF 0%', '#6F00B3 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#AFCCCB 0%', '#616566 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#4063BC 0%', '#6B0013 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#FFF500 0%', '#FF00B8 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#FFC328 0%', '#E20000 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#FFE70B 0%', '#27B643 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#FFADF7 0%', '#B1FF96 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#B7DCFF 0%', '#FFA4F6 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#5EE2FF 0%', '#00576A 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#D7003A 0%', '#19087E 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#FADD76 0%', '#9F3311 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#00E0EE 0%', '#AD00FE 100%'],
     },
     {
          id: nanoid(),
          direction: '220.55deg',
          type: 'linear-gradient',
          colors: ['#D0004B 0%', '#88069D 100%'],
     },
     {
          id: nanoid(),
          direction: 'circle at 30% 110%',
          type: 'radial-gradient',
          colors: ['#ffdb8b 0%', '#ee653d 25%', '#d42e81 50%', '#a237b6 75%', '#3e5fbc 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#04e2f7', '#1448d8'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#11AEEB', '#c13af1'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#11AEEB', '#35F39D'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#12C0DB', '#EF04F6'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#C542E3', '#FD0300'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#32C7E5', '#F078D5'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#CB5EEE', '#4BE1EC'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#FB1834', '#FB2B90'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#ED22C3', '#1E5EA0'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#DE97F9', '#9157FF'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#2C3E50', '#4CA0AE'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#0C52C4', '#00B1F3'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#FF5050', '#C13AF1'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#02C5F5', '#92FE9E'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#9560B9', '#E5CDF9'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#2DCDB0', '#F2FD6C'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#E3EE4C', '#18595C'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#F15A91', '#993A61'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#DD051B', '#1D00DC'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#64DBF3', '#7B78E3'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#60FBA3', '#0CAAA3'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#BB6CDB', '#FFCD41'],
     },
     {
          id: nanoid(),
          direction: 'circle at center top',
          type: 'radial-gradient',
          colors: ['rgb(209, 213, 219)', 'rgb(192, 38, 211)', 'rgb(234, 88, 12)'],
     },
     {
          id: nanoid(),
          direction: 'circle at center bottom',
          type: 'radial-gradient',
          colors: ['rgb(253, 230, 138)', 'rgb(124, 58, 237)', 'rgb(12, 74, 110)'],
     },
     {
          id: nanoid(),
          direction: 'at right center',
          type: 'radial-gradient',
          colors: ['rgb(56, 189, 248)', 'rgb(49, 46, 129)'],
     },
     {
          id: nanoid(),
          direction: 'at center bottom',
          type: 'radial-gradient',
          colors: ['rgb(120, 53, 15)', 'rgb(252, 211, 77)'],
     },
     {
          id: nanoid(),
          direction: 'at center center',
          type: 'radial-gradient',
          colors: ['rgb(88, 28, 135)', 'rgb(99, 102, 241)'],
     },
     {
          id: nanoid(),
          direction: '',
          type: 'linear-gradient',
          colors: ['rgb(17, 24, 39)', 'rgb(88, 28, 135)', 'rgb(124, 58, 237)'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['rgb(251, 146, 60)', 'rgb(56, 189, 248)'],
     },
     {
          id: nanoid(),
          direction: 'to right top',
          type: 'linear-gradient',
          colors: ['rgb(139, 92, 246)', 'rgb(253, 186, 116)'],
     },
     {
          id: nanoid(),
          direction: 'at center bottom',
          type: 'conic-gradient',
          colors: ['rgb(255, 255, 255)', 'rgb(14, 165, 233)', 'rgb(14, 165, 233)'],
     },
     {
          id: nanoid(),
          direction: 'at right center',
          type: 'conic-gradient',
          colors: ['rgb(199, 210, 254)', 'rgb(71, 85, 105)', 'rgb(199, 210, 254)'],
     },
     {
          id: nanoid(),
          direction: 'at left bottom',
          type: 'conic-gradient',
          colors: ['rgb(240, 171, 252)', 'rgb(52, 211, 153)', 'rgb(190, 18, 60)'],
     },
     {
          id: nanoid(),
          direction: 'at left top',
          type: 'conic-gradient',
          colors: ['rgb(14, 165, 233)', 'rgb(254, 215, 170)', 'rgb(217, 119, 6)'],
     },
     {
          id: nanoid(),
          direction: 'at left center',
          type: 'conic-gradient',
          colors: ['rgb(245, 158, 11)', 'rgb(168, 85, 247)', 'rgb(59, 130, 246)'],
     },
     {
          id: nanoid(),
          direction: 'at center top',
          type: 'conic-gradient',
          colors: ['rgb(17, 24, 39)', 'rgb(243, 244, 246)', 'rgb(17, 24, 39)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(15, 23, 42)', 'rgb(88, 28, 135)', 'rgb(15, 23, 42)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(251, 113, 133)', 'rgb(217, 70, 239)', 'rgb(99, 102, 241)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(110, 231, 183)', 'rgb(217, 70, 239)', 'rgb(192, 132, 252)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(253, 230, 138)', 'rgb(251, 207, 232)', 'rgb(244, 114, 182)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(253, 230, 138)', 'rgb(167, 243, 208)', 'rgb(110, 231, 183)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(147, 197, 253)', 'rgb(167, 243, 208)', 'rgb(252, 211, 77)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(192, 132, 252)', 'rgb(251, 191, 36)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(167, 243, 208)', 'rgb(52, 211, 153)', 'rgb(16, 185, 129)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(233, 213, 255)', 'rgb(192, 132, 252)', 'rgb(107, 33, 168)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(156, 163, 175)', 'rgb(75, 85, 99)', 'rgb(30, 64, 175)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(110, 231, 183)', 'rgb(252, 211, 77)', 'rgb(249, 168, 212)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(229, 231, 235)', 'rgb(156, 163, 175)', 'rgb(75, 85, 99)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(199, 210, 254)', 'rgb(254, 202, 202)', 'rgb(254, 243, 199)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(55, 65, 81)', 'rgb(17, 24, 39)', 'rgb(0, 0, 0)'],
     },
     {
          id: nanoid(),
          direction: 'to right',
          type: 'linear-gradient',
          colors: ['rgb(249, 168, 212)', 'rgb(216, 180, 254)', 'rgb(129, 140, 248)'],
     },
     {
          id: nanoid(),
          direction: 'from 80deg at -3% 0%',
          type: 'conic-gradient',
          colors: ['rgba(255, 255, 255, 1) 0%', 'rgb(14, 165, 233) 50%', 'rgb(14, 165, 233) 100%'],
     },
     {
          id: nanoid(),
          direction: '120deg',
          type: 'linear-gradient',
          colors: ['#185494', '#EE1D23', '#FAAF18', '#FFFFFF'],
     },
     {
          id: nanoid(),
          direction: '120deg',
          type: 'linear-gradient',
          colors: ['#185494', '#EE1D23', '#FAAF18', '#FFFFFF'],
     },
     {
          id: nanoid(),
          direction: '240deg',
          type: 'linear-gradient',
          colors: ['#f3f3f5', '#E90B8B', '#352384', '#352384'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#040308', '#AD4A28', '#DD723C', ' #FC7001', '#DCB697', '#9BA5AE', '#3E5879', '#020B1A'],
     },
     {
          id: nanoid(),
          direction: '151.84deg',
          type: 'linear-gradient',
          colors: ['#3BC5CE 45.85%', '#FFBB0D 72.21%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#99D4AD 0%', '#AAC9A7 13.02%', '#B7B898 19.27%', '#C69E84 25.88%', '#D56959 36.28%', '#D73A45 45.8%', '#B42447 55.23%', '#8E1445 64.43%', '#5F084F 74.72%', '#2A0059 86.46%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#09191C 0%', '#16343C 11.47%', '#10393F 18.62%', '#054243 25.23%', '#024A49 32.64%', '#01504D 40.18%', '#016058 48.39%', '#00716B 57.26%', '#008778 68.11%', '#3D9271 75.12%', '#C1BE50 100%'],
     },

     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#09191C 0%', '#16343C 11.47%', '#10393F 18.62%', '#054243 25.23%', '#024A49 32.64%', '#01504D 40.18%', '#016058 48.39%', '#00716B 57.26%', '#008778 68.11%', '#3D9271 75.12%', '#C1BE50 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#3CA9B0 0%', '#3E6DA5 20.47%', '#52619C 35.52%', '#655A94 44.12%', '#745591 52.15%', '#AC518C 63.19%', '#B8538D 69.36%', '#CB7D94 83.26%', '#E6C88C 100%'],
     },

     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#7B73A3 0%', '#7C75A4 7.84%', '#9790BA 23.83%', '#A096C0 29.22%', '#B49EC2 35.95%', '#C7A9C5 46.39%', ' #D6B2CC 54.61%', '#D8BBCF 63.72%', '#D5BACD 71.97%', '#CBC4D1 80.89%', '#A7CACC 90.82%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#0E1638 0.94%', '#0F224B 7.95%', '#133C71 16.55%', '#1C61A2 28.51%', '#3780B3 37.04%', '#5090B9 41.31%', '#ADB6B3 54.1%', '#C7C0B6 62.11%', '#C1BAB0 70.23%', '#C5A77F 80.76%', '#D98131 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#A9A7E3 0%', '#A6B0E3 8.33%', '#A0BAE2 20.31%', '#90D3E2 29.17%', '#8DD4D5 41.01%', '#94C4C4 49.48%', '#A6A5AD 59.9%', '#B293A2 67.71%', '#BE8095 74.48%', '#CF5C7D 84.37%', '#E93262 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#1C5152 0%', '#165C5F 4.13%', '#047573 13.26%', '#017D79 19.96%', '#169182 28.34%', '#479D82 35.49%', '#87A471 45.7%', '#C5A052 59.55%', '#D79944 68.69%', '#E09549 76.3%', '#DC924B 84.52%', '#B5B9B8 100%'],
     },
     {
          id: nanoid(),
          direction: '45deg',
          type: 'linear-gradient',
          colors: ['#E55D87 0%', '#5FC3E4 100%'],
     },
     {
          id: nanoid(),
          direction: '45deg',
          type: 'linear-gradient',
          colors: ['#FFB199 0%', '#FF0844 100%'],
     },
     {
          id: nanoid(),
          direction: '45deg',
          type: 'linear-gradient',
          colors: ['#92FE9D 0%', '#00C9FF 100%'],
     },
     {
          id: nanoid(),
          direction: '142.78deg',
          type: 'linear-gradient',
          colors: ['#ADFDA2 -22.92%', '#11D3F3 122.49%'],
     },
     {
          id: nanoid(),
          direction: '142.78deg',
          type: 'linear-gradient',
          colors: ['#9FCCFA -22.92%', '#0974F1 122.49%'],
     },
     {
          id: nanoid(),
          direction: '142.78deg',
          type: 'linear-gradient',
          colors: ['#8338E3 -22.92%', '#E0A9BB 122.49%'],
     },
     {
          id: nanoid(),
          direction: '142.78deg',
          type: 'linear-gradient',
          colors: ['#FFCAA6 -22.92%', '#F86594 122.49%'],
     },
     {
          id: nanoid(),
          direction: '142.78deg',
          type: 'linear-gradient',
          colors: ['#FC887B -22.92%', '#FFDD95 122.49%'],
     },
     {
          id: nanoid(),
          direction: '142.78deg',
          type: 'linear-gradient',
          colors: ['#9A9CE9 -22.92%', '#AEEEE0 122.49%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#47B2FE 12.73%', '#06ECFE 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#82E7FE 11.54%', '#6DB7FF 90.45%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#6DF7FF 12.73%', '#2DC9EB 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#98ADD9 12.73%', '#173E89 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#77E5D6 12.73%', '#9BB2E4 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#6714CC 12.73%', '#2E68F5 72.95%'],
     },
     {
          id: nanoid(),
          direction: '142.56deg',
          type: 'linear-gradient',
          colors: ['#322C7A 21.68%', '#30ABBD 80.35%'],
     },
     {
          id: nanoid(),
          direction: '142.29deg',
          type: 'linear-gradient',
          colors: ['#0068EC 21.8%', '#00BBF9 73.22%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#30B486 12.73%', '#5EEAB9 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#28A2B6 12.73%', '#4CB879 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#A0E9E5 12.73%', '#5CCDC7 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#CAC74C 12.73%', '#44AA76 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#DAEAD9 12.73%', '#C4E0C6 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#FFE5CC 12.73%', '#A1EA9A 72.95%'],
     },
     {
          id: nanoid(),
          direction: '142.15deg',
          type: 'linear-gradient',
          colors: ['#86F3BC 21.73%', '#8CDDE3 73.95%'],
     },
     {
          id: nanoid(),
          direction: '142.15deg',
          type: 'linear-gradient',
          colors: ['#FECDED 21.8%', '#FE9CA1 73.22%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#FA7695 12.73%', '#FDBE5B 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F7C96B 12.73%', '#FCA880 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F83800 12.73%', '#F8640A 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F06D5C 12.73%', '#ECB083 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F38C25 12.73%', '#FB6848 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#FFE7CD 12.73%', '#FCB9A2 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F6BFE9 12.73%', '#A28CD1 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#6974DC 12.73%', '#9679DD 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#403E9D 12.73%', '#9391F0 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#321F73 12.73%', '#A28CD1 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#FF877B 12.73%', '#D25A6E 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#FEA4A5 12.73%', '#FBC7BD 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#C73F90 11.54%', '#0E4FC1 90.45%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#4F3A8F 12.73%', '#EB3A4A 72.95%'],
     },
     {
          id: nanoid(),
          direction: '142.29deg',
          type: 'linear-gradient',
          colors: ['#CF9091 21.8%', '#C72370 73.22%'],
     },
     {
          id: nanoid(),
          direction: '142.29deg',
          type: 'linear-gradient',
          colors: ['#6973DB 21.8%', '#FE9CA1 73.22%'],
     },
     {
          id: nanoid(),
          direction: '163deg',
          type: 'linear-gradient',
          colors: ['#82E7FE 11.8%', '#6DB7FF 90.22%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F7F2EE 12.73%', '#E7D9CD 72.95%'],
     },
     {
          id: nanoid(),
          direction: '202.03deg',
          type: 'linear-gradient',
          colors: ['#F7839A 14.4%', '#7CDADA 78.24%'],
     },
     {
          id: nanoid(),
          direction: 'at center',
          type: 'radial-gradient',
          colors: ['#A86AA3 21.8%', '#F1906D 73.22%'],
     },
     {
          id: nanoid(),
          direction: '147.88deg',
          type: 'linear-gradient',
          colors: ['#DBA17A 19.29%', '#C9927F 62.61%'],
     },
     {
          id: nanoid(),
          direction: '163.3deg',
          type: 'linear-gradient',
          colors: ['#B1C0DB 11.54%', '#728CBA 90.45%'],
     },
     {
          id: nanoid(),
          direction: '163.3deg',
          type: 'linear-gradient',
          colors: ['#E3EDFE 11.54%', '#E7D9CD 90.45%'],
     },
     {
          id: nanoid(),
          direction: '163.3deg',
          type: 'linear-gradient',
          colors: ['#D9DBDE 11.54%', '#A8AAAE 90.45%'],
     },
     {
          id: nanoid(),
          direction: '163.3deg',
          type: 'linear-gradient',
          colors: ['#A1BDB1 11.54%', '#665263 90.45%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#757575 12.73%', '#050505 72.95%'],
     },
     {
          id: nanoid(),
          direction: '202.03deg',
          type: 'linear-gradient',
          colors: ['#FDA670 14.4%', '#7CDADA 78.24%'],
     },
     {
          id: nanoid(),
          direction: '163.3deg',
          type: 'linear-gradient',
          colors: ['#A8C1ED 11.54%', '#F3C1EB 90.45%'],
     },
     {
          id: nanoid(),
          direction: '142.29deg',
          type: 'linear-gradient',
          colors: ['#6973DB 21.8%', '#FE9CA1 73.22%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F6E9D3 12.73%', '#D5A0C3 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#F7EAEA 12.73%', '#FF9DA1 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#C569CF 12.73%', '#EE609C 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#611447 12.73%', '#E66A83 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#FFA492 12.73%', '#FF2C55 72.95%'],
     },
     {
          id: nanoid(),
          direction: '161.15deg',
          type: 'linear-gradient',
          colors: ['#FEA4A5 12.73%', '#FBC7BD 72.95%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#C7CCDA 0%', '#DCD5C2 25.52%', '#D5C7A4 40.63%', '#B49F71 57.29%', '#725D37 73.44%', '#221B19 96.35%'],
     },

     {
          id: nanoid(),
          direction: '144deg',
          type: 'linear-gradient',
          colors: ['#AF40FF', '#5B42F3 50%', '#00DDEB'],
     },
     {
          id: nanoid(),
          direction: '60deg',
          type: 'linear-gradient',
          colors: ['#f79533', '#f37055', '#ef4e7b', '#a166ab', '#5073b8', '#1098ad', '#07b39b', '#6fba82'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#ECECEC 0%', '#BBBBBB 48.44%', '#3E3E3E 48.45%', '#969696 100%'],
     },

     {
          id: nanoid(),
          direction: '84.87% 84.87% at 78.68% 24.79%',
          type: 'radial-gradient',
          colors: ['#DCC8D0 0%', '#A3C8D0 20.83%', '#66B3BA 33.33%', '#386DB2 48.44%', '#352214 74.48%', '#5F3C2D 86.98%', '#2D1C13 100%'],
     },
     {
          id: nanoid(),
          direction: '90.15% 90.15% at 68.95% 9.85%',
          type: 'radial-gradient',
          colors: ['#6BD0EA 0%', '#ABDEE7 14.06%', '#BBE3E7 30.73%', '#ECE5CF 46.35%', '#E7DBD8 62.5%', '#AD8FE5 79.69%', '#4F2FE6 97.92%'],
     },
     {
          id: nanoid(),
          direction: '103deg',
          type: 'linear-gradient',
          colors: ['#b7ab99 3.56%', '#ffa235 32.81%', '#cf5c2a 56.49%', '#f6543c 92.71%'],
     },
     {
          id: nanoid(),
          direction: '225deg',
          type: 'linear-gradient',
          colors: ['#32C5FF 0%', '#ffa235 32.81%', '#B620E0 51%', '#F7B500 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#E02020 0%', '#FA6400 17%', '#F7B500 33%', '#6DD400 50%', '#0091FF 67%', '#6236FF 83%', '#B620E0 100%'],
     },
     {
          id: nanoid(),
          direction: 'to bottom',
          type: 'linear-gradient',
          colors: ['#f3b167', '#ec38bc', '#7303c0', '#03001e'],
     },
     {
          id: nanoid(),
          direction: '45deg',
          type: 'linear-gradient',
          colors: ['rgb(255, 25, 125)', 'rgb(45, 13, 255)', 'rgb(0, 255, 179)'],
     },
     {
          id: nanoid(),
          direction: '45deg',
          type: 'linear-gradient',
          colors: ['rgb(217, 244, 255)', 'rgb(255, 143, 167)', 'rgb(93, 104, 255)'],
     },
     {
          id: nanoid(),
          direction: '45deg',
          type: 'linear-gradient',
          colors: ['rgb(94, 114, 235)', 'rgb(255, 145, 144)', 'rgb(254, 193, 149)'],
     },
     {
          id: nanoid(),
          direction: '135deg',
          type: 'linear-gradient',
          colors: ['#0E0220 0%', '#000000 6px', '#E40475 24.8%', '#0E0220 35%', '#48E0E4 56.3%', '#48E0E4 62.4%', '#FF00C8 72.9%', '#0000DB 78.5%', '#48E0E4 100%'],
     },

     {
          id: nanoid(),
          direction: '225deg',
          type: 'linear-gradient',
          colors: ['#0094FF 1%', '#BFF4ED 45.5%', '#FF004E 60.6%', '#280F34 70.3%', '#B30753 81.6%', '#E41655 85.1%', '#B30753 100%'],
     },

     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#342427 0%', '#3D1E36 16.15%', '#D91C34 30.73%', '#FE7D3C 38.02%', '#FB7A64 50%', '#A33445 61.98%', '#6F2E4C 71.35%', '#632247 76.04%', '#431D44 84.37%', '#24213B 92.71%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#0D0B1C 0%', '#15325C 14.06%', '#4F72B6 25%', '#DFA8BD 38.54%', '#F9C6B8 48.44%', '#F2E1E8 61.46%', '#DBD7FB 67.19%', '#A3AEED 77.08%', '#B3BBFA 89.58%', '#3E6FD8 98.44%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#2D0A46 0%', '#5B4C73 6.91%', '#DDB79B 14.23%', '#E99581 19.73%', '#7E6FAC 28.88%', '#776497 37.3%', '#776396 47.19%', '#7A6DB2 59.28%', '#9482C9 69.9%', '#D596C0 75.03%', '#EF9099 80.88%', '#EDBF8D 88.21%', '#B0AC91 94.07%', '#51416D 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#CE375F 0%', '#F88032 5.21%', '#EEC629 15.62%', '#D8BC1D 25.52%', '#DB843C 36.98%', '#D76258 46.35%', '#D24664 55.73%', '#AF15B1 66.67%', '#873AC6 78.12%', '#446AF1 84.9%', '#373DBA 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#ECADAF 0%', '#E0B6A5 13.54%', '#DC8B9D 30.21%', '#C05AC9 44.27%', '#B865CF 57.81%', '#C3A7D9 71.87%', '#C5C1E2 84.9%', '#C6D8E4 100%'],
     },
     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#E9ECEF 0%', '#BFCEDB 40.1%', '#8E78E6 51.56%', '#C063C4 63.02%', '#D46EBE 74.48%', '#D965A0 85.42%', '#D55E81 92.19%', '#E67A78 100%'],
     },
     {
          id: nanoid(),
          direction: '140deg',
          type: 'linear-gradient',
          colors: ['hsl(195, 54%, 37%)', 'hsl( 40, 91%, 63%) 29%', 'hsl(236, 84%, 68%) 71%', 'hsl( 25, 84%, 78%)'],
     },

     {
          id: nanoid(),
          direction: '180deg',
          type: 'linear-gradient',
          colors: ['#6990A6 0%', '#CDADF7 22.4%', '#D6C4FA 38.02%', '#CBCDFA 51.04%', '#C0CEFA 64.58%', '#A3C9F8 74.48%', '#69A8F7 86.98%', '#537ABB 100%'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#F9A8D4', '#FED7AA', '#FCA5A5'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#86EFAC', '#FEF08A', '#BBF7D0'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#BBF7D0', '#DBEAFE', '#93C5FD'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#A5B4FC', '#60A5FA', '#A855F7'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#FCA5A5', '#FDBA74', '#FEF08A'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#F9A8D4', '#F472B6', '#F87171'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#94A3B8', '#737373', '#404040'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#FDBA74', '#FB923C', '#F87171'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#5EEAD4', '#22D3EE'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#FCA5A5', '#9333EA'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#4361EE', '#52EDFF'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#F72585', '#52EDFF', '#52EDFF'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#7209B7', '#F06595'],
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#FCC419', '#FF922B', '#F72585'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#fcc5e4 0%', '#fda34b 15%', '#ff7882 35%', '#c8699e 52%', '#7046aa 71%', '#0c1db8 87%', '#020f75 100%'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#dbdcd7 0%', '#dddcd7 24%', '#e2c9cc 30%', '#e7627d 46%', '#b8235a 59%', '#801357 71%', '#3d1635 84%', '#1c1a27 100%'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#3f51b1 0%', '#5a55ae 13%', '#7b5fac 25%', '#8f6aae 38%', '#a86aa4 50%', '#cc6b8e 62%', '#f18271 75%', '#f3a469 87%', '#f7c978 100%'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#e8198b 0%', '#c7eafd 100%'],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#3b41c5 0%', '#a981bb 49%', '#ffc8a9 100%'],
     },
     {
          id: nanoid(),
          direction: '135deg',
          type: 'linear-gradient',
          colors: ['#231557 0%', '#44107A 29%', '#FF1361 67%', '#FFF800 100%'],
     },
     {
          id: nanoid(),
          direction: '135deg',
          type: 'linear-gradient',
          colors: ['#3B2667', '#BC78EC'],
     },
     {
          id: nanoid(),
          direction: 'circle farthest-corner at 10% 20%',
          type: 'radial-gradient',
          colors: ['rgba(255,94,247,1) 17.8%', 'rgba(2,245,255,1) 100.2%'],
     },
     {
          id: nanoid(),
          direction: '180.4deg',
          type: 'linear-gradient',
          colors: ['rgba(188,120,236,1) -2.2%', 'rgba(29,133,163,1) 83.5%'],
     },
     {
          id: nanoid(),
          direction: '45deg',
          type: 'linear-gradient',
          colors: ['#FA8BFF 0%', '#2BD2FF 52%', '#2BFF88 90% '],
     },
     {
          id: nanoid(),
          direction: 'to top',
          type: 'linear-gradient',
          colors: ['#151C60', '#C6CBF5 40%', 'black 40%', '#E1A0CE 65%', 'white'],
     },
     {
          id: nanoid(),
          direction: '184deg',
          type: 'linear-gradient',
          colors: ['rgba(235,81,254,0.8)', 'rgba(151,99,163,0.4)', 'rgba(228,134,225,0.2)'],
     },
     {
          id: nanoid(),
          direction: 'from 90deg at 50% 125%',
          type: 'conic-gradient',
          colors: ['#1f005c', '#003298', '#005ac6', '#007fdc', '#00a2d3', '#00c4ae', '#00e474', '#00ff00', '#1f005c', '#003298', '#005ac6', '#007fdc', ' #00a2d3', '#00c4ae', '#00e474', '#00ff00'],
     },
     {
          id: nanoid(),
          direction: 'from 90deg at 50% 125%',
          type: 'conic-gradient',
          colors: ['#20b2aa', '#135da5', '#0d0895', '#4b0082', '#4b0082', '#0d0895', '#135da5', '#20b2aa'],
     },
     {
          id: nanoid(),
          direction: 'from -.5turn at bottom right',
          type: 'conic-gradient',
          colors: ['deeppink', 'cyan', 'rebeccapurple'],
     },
     {
          id: nanoid(),
          direction: 'at bottom left',
          type: 'conic-gradient',
          colors: ['deeppink', 'cyan']
     },
     {
          id: nanoid(),
          direction: '100% 100% at 50% 0',
          type: 'radial-gradient',
          colors: ['#210CA0 48.71%', '#266CD5 100%'],
     },
     {
          id: nanoid(),
          direction: '99.21% 99.21% at 50% 0',
          type: 'radial-gradient',
          colors: ['#B23BA6 0%', '#7D20CF 65.66%', '#200B9F 100%']
     },
     {
          id: nanoid(),
          direction: '103.58deg',
          type: 'linear-gradient',
          colors: ['#B6D0F7 0.9%', '#EEF4E1 21.12%', '#E2C1F9 39.05%', '#BFD1F9 52.2%', '#FAFCFE 62.83%', '#BAE4E2 71.67%', '#8C6BE3 89.14%']
     },
     {
          id: nanoid(),
          direction: '140% 107% at 50% 10%',
          type: 'radial-gradient',
          colors: ['#000314 37.41%', '#6633EE 69.27%', '#FFFFFF 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#1CB5E0 0%', '#000851 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#00C9FF 0%', '#92FE9D 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#FC466B 0%', '#3F5EFB 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#FDBB2D 0%', '#22C1C3 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#FDBB2D 0%', '#3A1C71 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#e3ffe7 0%', '#d9e7ff 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#4b6cb7 0%', '#182848 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#9ebd13 0%', '#008552 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#0700b8 0%', '#00ff88 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#d53369 0%', '#daae51 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#efd5ff 0%', '#515ada 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#00d2ff 0%', '#3a47d5 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#f8ff00 0%', '#3ad59f 100%']
     },
     {
          id: nanoid(),
          direction: '90deg',
          type: 'linear-gradient',
          colors: ['#fcff9e 0%', '#c67700 100%']
     },
     {
          id: nanoid(),
          direction: 'to bottom right',
          type: 'linear-gradient',
          colors: ['#55566a', '#282834']
     },





];


































