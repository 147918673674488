import React from 'react'
import { Link } from 'react-router-dom'
import { HeaderItem } from '../AllContent/Content'
import { logo, title } from '../Config/Config'

const HomeHeader = (props) => {


     return (
          <div className={`__z-header ${props.scrolled ? "z-header-fix" : ""}`}>
               <div className='__container' >
                    <Link to="/" className='_logo'>
                         <img className='__logo2' src={logo} alt={title + "logo"} />
                    </Link>

                    <div className='__list'>
                         <ul className='nav'>
                              {HeaderItem.map((item, index) => (
                                   <li className='nav-item' key={index}>
                                        <Link to={item.path} className="nav-link"> {item.label}* </Link>
                                   </li>
                              ))}
                         </ul>
                    </div>
               </div>
          </div>
     )
}

export default HomeHeader