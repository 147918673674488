import React from 'react';
import { Accordion } from 'react-bootstrap';

const R3f = () => {
     return (
          <section className='__docs'>
               <h1 className='heading'> Docs SKill jh </h1>

               <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="0">
                    <Accordion.Header>1. Installation React Three fiber Start basic</Accordion.Header>
                         <Accordion.Body>
                              <ul>
                              <li>sudo npm i three js</li>
                                   <li>sudo npm i @react-three/fiber </li>
                                   <li> sudo npm i @react-three/drei</li>
                                   {/* <li> sudo npm i @react-three/rapier</li>
                                   <li>sudo npm i lamina</li> */}

                                   <li className='mt-4'>This tutorial will assume some React knowledge. 
                                        <br />

                                        <pre>
                                             {`<Canvas>
     <mesh>
     <boxGeometry />
     <meshStandardMaterial />
     </mesh>
</Canvas>`}
                                        </pre>
                                   </li>

                                   <li>
                                        <pre className='mt-3'>
                                             {` <boxGeometry args={[11, 1, 1]} />`} <br />
                                             arrgs first 11 means Left/Right Width , center "1" Top/bottom Height , Last "1" Means Scale All Box Apply
                                        </pre>
                                   </li>
                                   <li>
                                        <pre className='mt-3'>
                                             {` <ambientLight intensity={1} />`} <br />
                                             ambientLight - Your Box Show Color for This properties 
                                        </pre>
                                   </li>

                              </ul>
                         </Accordion.Body>
                    </Accordion.Item>

               </Accordion>

          </section>
     );
}

export default R3f;





// https://gltf.pmnd.rs/ - as a demo 
// apartment, city, dawn, forest, lobby, night, park, studio, sunset, warehouse
