import React from "react";
import styles from "./BlogPage.module.scss";

interface BlogPost {
     id: number;
     title: string;
     date: string;
     excerpt: string;
     link: string;
}

const blogPosts: BlogPost[] = [
     {
          id: 1,
          title: "How to Improve SEO for Your Website",
          date: "November 23, 2024",
          excerpt: "Learn essential tips to boost your website's search engine ranking with proper SEO strategies.",
          link: "/blog/seo-tips",
     },
     {
          id: 2,
          title: "React TypeScript: A Beginner's Guide",
          date: "November 20, 2024",
          excerpt: "Discover how to get started with React and TypeScript to build scalable and maintainable applications.",
          link: "/blog/react-typescript",
     },
     {
          id: 3,
          title: "Mastering SCSS for Modern Web Development",
          date: "November 18, 2024",
          excerpt: "Understand how SCSS simplifies styling for modern web applications with advanced features.",
          link: "/blog/mastering-scss",
     },
];

const BlogPage: React.FC = () => {
     return (
          <div className="blogPage">
               <div className="header">
                    <h1 className="title">Our Blog</h1>
                    <p className="subtitle">Insights, Tutorials, and Best Practices</p>
               </div>
               <div className="blogList">
                    {blogPosts.map((post) => (
                         <div key={post.id} className="blogCard">
                              <h2 className="blogTitle">{post.title}</h2>
                              <p className="blogDate">{post.date}</p>
                              <p className="blogExcerpt">{post.excerpt}</p>
                              <a href={post.link} className="readMore">
                                   Read More →
                              </a>
                         </div>
                    ))}
               </div>
          </div>
     );
};

export default BlogPage;
