import React, { useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { instaLink, linkedinLink, title } from '../../Config/Config';
import { Link } from 'react-router-dom';
import { initialGradients } from './GradientData';
import toasted from '../../Config/toast';
import { CopyAll } from '@mui/icons-material';
import Loader from '../../Config/Loader';


const ColosTool = () => {

     const [visibleGradients, setVisibleGradients] = useState(40); // Initial number of items to show
     const renderedColors = new Set<string>(); // Set to track rendered colors


     const cleanColorValue = (color: string) => {

          if (/^(hsl|hsla|rgb|rgba)\(/.test(color)) {
               return color.replace(/(?:\s*\d+%?\s*|\s*\d+\.\d+%?\s*|\s*-\d+%?\s*|\s*-\d+\.\d+%?\s*|\s*\d+px\s*|\s*-\d+px\s*|\s*-\d+\.\d+px\s*|\s*\d+px\s*)$/, '');
          }

          if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
               return color; // Return unchanged if it's a valid hex
          } else if (/^#[0-9A-Fa-f]{6}\s*-?\d+(\.\d+)?%?$/.test(color)) {

               return color.replace(/(?:\s*\d+%?\s*|\s*-\d+%?\s*|\s*\d+\.\d+%?\s*|\s*-\d+\.\d+%?\s*)$/, '');
          }

          const namedColors = ['black', 'white', 'yellow', 'red', 'green', 'blue', 'purple', 'cyan', 'magenta', 'orange'];
          const regex = new RegExp(`\\b(${namedColors.join('|')})\\s+\\d+%?\\b`, 'i');

          if (regex.test(color)) {
               return color.replace(/\s+\d+%?$/, '');
          }

          return color;
     };






     const totalColors = initialGradients.reduce((acc, gradient) => acc + gradient.colors.length, 0);
     let globalIndex = 0;


     // Copy gradient to clipboard 
     const colorCopyFunction = (bgcode: any) => {
          console.log(globalIndex)
          const copytxt = bgcode;
          navigator.clipboard.writeText(copytxt);
          toasted.success(`copied ${bgcode} to clipboard!`);
     };

     // seen more button 
     const loadMoreColors = () => {
          setVisibleGradients(prevVisible => prevVisible + 40); // Load 40 more items
     };



     return (
          <> 
               <Loader timer={1000} />

          <div className='__gradient-qw1'>
               <ParallaxProvider>
                    <div className='__container'>
                         <div className='qw-gradinet-heading'>
                              <h2>{title} Get HTML {totalColors - 4}+ color codes, <br />Hex color codes, RGB and HSL values Let's go!</h2>
                              <p>Our team creates useful tools for web designers and Graphic designers</p>
                              <p>Created by
                                   <Link to={linkedinLink} title='linkdin' target='_blank'> @sunilsharma</Link> or
                                   <Link title='Instagram' to={instaLink} target='_blank'> @thedc_</Link>
                              </p>
                         </div>

                         <div className='__qw-section'>
                              <div className='row'>
                                   {initialGradients.slice(0, visibleGradients).map((gradient) =>
                                        gradient.colors.map((color, index) => {
                                             const cleanedColor = cleanColorValue(color);

                                             // Skip rendering if the color has already been rendered
                                             if (renderedColors.has(cleanedColor)) {
                                                 return null;
                                             }
         
                                             // Add the color to the Set to track it
                                             renderedColors.add(cleanedColor);
                                             globalIndex++;
                                             return (
                                                  <div className='col-lg-4 col-md-6' key={`${gradient.id}-${index}`}>
                                                       <Parallax>
                                                            <div className='__color-card' style={{ backgroundColor: cleanColorValue(color) }}>
                                                                 <div className={`info-color`}>
                                                                      <div className='__name'> Color Number #{globalIndex}</div>
                                                                      <div className='__code'>{cleanColorValue(color)}</div>
                                                                 </div>

                                                                 <button type='button' className='btn --txt-white' onClick={() => colorCopyFunction(cleanColorValue(color))}>
                                                                      <CopyAll />
                                                                 </button>
                                                            </div>
                                                       </Parallax>
                                                  </div>
                                             );
                                        })
                                   )}
                              </div>
                              {visibleGradients < totalColors && (
                                   <button className="btn SeenBtn" onClick={loadMoreColors}> Show More </button>
                              )}
                         </div>
                    </div>
               </ParallaxProvider>
          </div>

          </>

     );
}

export default ColosTool;
