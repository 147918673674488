import React from 'react';
import { Link } from 'react-router-dom';
import { websites } from '../../AllContent/Data';
// import { getCookie } from 'typescript-cookie';
const CanvaSection = React.lazy(() => import('../../Home/CanvaSection'));


const Banner = () => {

    // const auth = getCookie("auth");

    return (
        <>

            <section className='banner'>
                <div className='__wapper'>
                    <div className='row'>

                        {/* Text Content */}
                        <div className='col-lg-6'>
                            <div className='__txt'>
                                <div>
                                    <h1>
                                        Design... Develop... <span className="color-txt">Deliver...</span>
                                    </h1>
                                </div>
                                <p>Turning ideas into digital experiences that inspire and deliver results. Let’s create something extraordinary!</p>

                                <div className='__links'>
                                    <Link to="/" aria-label="Join our team">Join in Team</Link>
                                </div>
                            </div>
                        </div>

                        {/* Canva Section */}
                        <div className='col-lg-6'>
                            <div className='__canva' aria-label="Creative canvas section">
                                <CanvaSection />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='__favourite_web'>
                <h2 className='section-heading'>Discover Websites Trusted by Leaders Worldwide</h2>

                <div className="website-marquee">
                    <div className="marquee-content">
                        {websites.map((val, index) => (
                            <Link to={val.url} target="_blank" rel="noopener noreferrer" key={index}
                                className="marquee-item">
                                <img src={val.imageUrl} alt={val.name} className="marquee-img" />
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner;
