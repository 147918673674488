import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from 'typescript-cookie'


function PrivateRoutes() {
    // const auth = localStorage.getItem('auth');
    const auth = getCookie('auth');

    return auth ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoutes