import { title } from '../Config/Config';
import HelmetSEO from '../Config/HelmetSEO';
import Loader from '../Config/Loader';
import Banner from './indexComponents/Banner';
import About from './indexComponents/About';
import Services from './indexComponents/Services';



const Index = () => {

     return (
          <>
               <HelmetSEO
                    title={`Home | ${title}`}
                    description="Welcome to our index! Explore our amazing features and services."
                    keywords="index, thedc react, typescript, website designer, frontend developer, Portfolio website , sunil sharma,"
               />

               {/* <Loader timer={1000} /> */}
               <Banner />
               <About/>
               <Services/>
               

               
          </>
     );
}

export default Index;


