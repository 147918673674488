import React from 'react'
import { Helmet } from 'react-helmet-async';


const HelmetSEO = (props: any) => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            {props.keywords && <meta name="keywords" content={`thedc , sunil sharma ,` + props.keywords} />}
        </Helmet>
    )
}

export default HelmetSEO