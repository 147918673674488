import React, { useEffect, useState } from 'react';

const SvgPath: React.FC = () => {
     const [strokeDasharray, setStrokeDasharray] = useState<string>('0');
     const [isVisible, setIsVisible] = useState<boolean>(false);

     useEffect(() => {
          const svgSection = document.querySelector('.__animation-sec');

          const handleScroll = () => {
               if (isVisible && svgSection) {
                    const svgRect = svgSection.getBoundingClientRect();
                    const windowHeight = window.innerHeight;
                    const scrollPercent = Math.max(0, Math.min(2, (windowHeight - svgRect.top) / windowHeight));

                    // Update strokeDasharray based on visibility
                    const maxArray = 1650; // Example value, adjust as needed
                    const newArray = maxArray * scrollPercent;
                    setStrokeDasharray(`${newArray}px`);
               }
          };

          const observer = new IntersectionObserver(
               (entries) => {
                    entries.forEach((entry) => {
                         if (entry.isIntersecting) {
                              setIsVisible(true);
                         } else {
                              setIsVisible(false);
                         }
                    });
               },
               { threshold: 0 }
          );

          if (svgSection) {
               observer.observe(svgSection);
          }

          window.addEventListener('scroll', handleScroll);

          return () => {
               window.removeEventListener('scroll', handleScroll);
               if (svgSection) {
                    observer.unobserve(svgSection);
               }
          };
     }, [isVisible]);

     return (
          <div className="__svg-sec">
               <div className="__top-sec"></div>


               <div className="__animation-sec">
                    {/* Back side Image create a simple outline like d-sade colors */}
                    <div className="__svg-outer">
                         {/* <svg id="visual" viewBox="0 0 1600 500" width="1600" height="500" xmlns="http://www.w3.org/2000/svg" version="1.1">
                              <path d="M0 389L22.2 384.8C44.3 380.7 88.7 372.3 133.2 356.7C177.7 341 222.3 318 266.8 298C311.3 278 355.7 261 400 224.3C444.3 187.7 488.7 131.3 533.2 140.5C577.7 149.7 622.3 224.3 666.8 280.2C711.3 336 755.7 373 800 342.7C844.3 312.3 888.7 214.7 933.2 149.8C977.7 85 1022.3 53 1066.8 62.3C1111.3 71.7 1155.7 122.3 1200 126C1244.3 129.7 1288.7 86.3 1333.2 71.8C1377.7 57.3 1422.3 71.7 1466.8 104.5C1511.3 137.3 1555.7 188.7 1577.8 214.3L1600 240"
                                   fill="none" stroke="#ddd" stroke-width="5" stroke-linecap="round" stroke-linejoin="miter">
                              </path>
                         </svg> */}


                         <svg id="visual" viewBox="0 0 1600 500" width="1600" height="500" xmlns="http://www.w3.org/2000/svg" version="1.1">
                              <path d="M0 389L22.2 384.8C44.3 380.7 88.7 372.3 133.2 356.7C177.7 341 222.3 318 266.8 298C311.3 278 355.7 261 400 224.3C444.3 187.7 488.7 131.3 533.2 140.5C577.7 149.7 622.3 224.3 666.8 280.2C711.3 336 755.7 373 800 342.7C844.3 312.3 888.7 214.7 933.2 149.8C977.7 85 1022.3 53 1066.8 62.3C1111.3 71.7 1155.7 122.3 1200 126C1244.3 129.7 1288.7 86.3 1333.2 71.8C1377.7 57.3 1422.3 71.7 1466.8 104.5C1511.3 137.3 1555.7 188.7 1577.8 214.3L1600 240"
                                   fill="none" stroke="#ddd" stroke-width="5" stroke-linecap="round" stroke-linejoin="miter">
                              </path>
                         </svg>
                    </div>

                    <div className="__svg-outer">
                         {/* these main Image  */}

                         {/* <svg id="visual" viewBox="0 0 1600 500" width="1600" height="500" xmlns="http://www.w3.org/2000/svg" version="1.1">
                              <path d="M0 389L22.2 384.8C44.3 380.7 88.7 372.3 133.2 356.7C177.7 341 222.3 318 266.8 298C311.3 278 355.7 261 400 224.3C444.3 187.7 488.7 131.3 533.2 140.5C577.7 149.7 622.3 224.3 666.8 280.2C711.3 336 755.7 373 800 342.7C844.3 312.3 888.7 214.7 933.2 149.8C977.7 85 1022.3 53 1066.8 62.3C1111.3 71.7 1155.7 122.3 1200 126C1244.3 129.7 1288.7 86.3 1333.2 71.8C1377.7 57.3 1422.3 71.7 1466.8 104.5C1511.3 137.3 1555.7 188.7 1577.8 214.3L1600 240"
                                   fill="none"
                                   stroke="#0066FF"
                                   stroke-width="5"
                                   stroke-linecap="round"
                                   stroke-linejoin="miter"
                                   style={{
                                        strokeDashoffset: '0px',
                                        strokeDasharray: `${strokeDasharray}, 2524.13px`,
                                        transition: 'stroke-dasharray 0.2s linear',
                                   }}
                              >
                              </path>
                         </svg> */}



                         <svg id="visual" viewBox="0 0 1600 500" width="1600" height="500" xmlns="http://www.w3.org/2000/svg" version="1.1">
                              <path d="M0 389L22.2 384.8C44.3 380.7 88.7 372.3 133.2 356.7C177.7 341 222.3 318 266.8 298C311.3 278 355.7 261 400 224.3C444.3 187.7 488.7 131.3 533.2 140.5C577.7 149.7 622.3 224.3 666.8 280.2C711.3 336 755.7 373 800 342.7C844.3 312.3 888.7 214.7 933.2 149.8C977.7 85 1022.3 53 1066.8 62.3C1111.3 71.7 1155.7 122.3 1200 126C1244.3 129.7 1288.7 86.3 1333.2 71.8C1377.7 57.3 1422.3 71.7 1466.8 104.5C1511.3 137.3 1555.7 188.7 1577.8 214.3L1600 240"
                                   fill="none" stroke="#0066FF" stroke-width="5" stroke-linecap="round" stroke-linejoin="miter"
                                   style={{
                                        strokeDashoffset: '0px',
                                        strokeDasharray: `${strokeDasharray}, 2524.13px`,
                                        transition: 'stroke-dasharray 0.2s linear',
                                   }}>
                              </path>
                         </svg>

                    </div>
               </div>
          </div>
     );
};

export default SvgPath;
