import React from 'react';
import Loader from '../Config/Loader';

const Overview = () => {

  return (
    <main>
      <Loader timer={1000} />


      <div className='__container'>


      </div>
    </main>
  );
}

export default Overview;
