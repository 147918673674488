import React, { useEffect, useState } from 'react'
import CanvaSection from './CanvaSection';
import HomeHeader from './HomeHeader';

const Homepage = () => {

     const [scrolled, setScrolled] = useState(false);

     useEffect(() => {
          const handleScroll = () => {
               if (window.scrollY > 100) {
                    setScrolled(true);
                    document.body.classList.add("scrolled"); // Body me class add karna
               } else {
                    setScrolled(false);
                    document.body.classList.remove("scrolled"); // Body me class remove karna
               }
          };

          window.addEventListener("scroll", handleScroll);

          return () => window.removeEventListener("scroll", handleScroll);
     }, []);



     return (
          <>

               <main className='__home'>

                    <div className='__canveujs'>
                         <HomeHeader scrolled={scrolled} />
                         <CanvaSection scrolled={scrolled} />


                         <div className={`__overlyBanner ${scrolled ? " hidden" : ""}`}>
                              <div className='__container'>
                                   <p> Our long experience and design sagacity help us spot essential things while selecting a design that business owners often miss. <br/> Neutron and invested into 150+ projects </p>
                                   <h1> Branding & Design Experts</h1>
                              </div>
                         </div>


                         
                    </div>
               </main>


          </>
     )
}

export default Homepage