import React from 'react';
import Loader from '../Config/Loader';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import About from './indexComponents/About';
import me_img from '../Assets/Image/meThedc.png'

const AboutPage = () => {

      return (
            <>
                  <Loader timer={1000} />

                  <section className='ai'>
                        <ParallaxProvider>
                              <Parallax speed={5}>
                                    <div className='__headings'>
                                          <div className='__container'>
                                                <div className='row'>
                                                      <div className='col-12'>
                                                            <div className='__left'>
                                                                  <h2>About Me - Design with Passion and Precision</h2>
                                                                  <h4>a website designer dedicated to creating unique and impactful digital experiences. Learn more about my journey, skills, and <br /> the creative process that drives my work.</h4>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </Parallax>
                        </ParallaxProvider>

                        <div className='__aboutpage'>
                              <About imgTdc={me_img} />
                        </div>
                  </section>
            </>
      );
}

export default AboutPage;
