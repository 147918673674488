import React, { useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Modal from 'react-responsive-modal';
import { codeInfomation } from '../../AllContent/CodeData';
import { ContentCopy, VpnLock } from '@mui/icons-material';
import toasted from '../../Config/toast';
import Loader from '../../Config/Loader';
import { Link } from 'react-router-dom';

const CodeInfo = () => {

      const [open, setOpen] = useState(false);
      const [selectedItem, setSelectedItem] = useState<any>(null);

      const getRandomCardClass = () => {
            const classes = ['qw-1', 'qw-2', 'qw-3'];
            return classes[Math.floor(Math.random() * classes.length)];
      };

      const handleOpenModal = (item: any) => {
            setSelectedItem(item);
            setOpen(true);
      };

      const handleCloseModal = () => {
            setOpen(false);
            setSelectedItem('');
      };


      const handleCopyCode = () => {
            if (selectedItem && selectedItem.code) {
                  navigator.clipboard.writeText(selectedItem.code).then(() => {
                        toasted.success('Code copied to clipboard!');
                  }).catch(err => {
                        console.error('Failed to copy code: ', err);
                        toasted.error('Failed to copy code.');
                  });
            }
      };

      const handleRemove = () => {
            localStorage.removeItem('isLocked');
            window.location.reload();
      };


      return (
            <>

                  <Loader timer={1000} />

                  <section className='ai'>
                        <ParallaxProvider>
                              <Parallax speed={5}>
                                    <div className='__headings pt-5'>
                                          <div className='__container'>
                                                <div className='row pt-3'>
                                                      <div className='col-md-6'>
                                                            <div className='__left'>
                                                                  <button type='button' onClick={handleRemove} className='btn copt-btn'> <VpnLock /> </button>
                                                                  <h2> Explore Your Favorite Code and Idea</h2>
                                                                  <h4>Crafting Unique Experiences with HTML, CSS, SCSS, Tailwind css, Bootsrap,  React js and typescript , My Idea Or ChatGPT </h4>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </Parallax>

                              <div className='__container'>
                                    <div className='__sec'>
                                          <div className='__cards'>
                                                <div className='row'>
                                                      {codeInfomation.map((item, index) => (
                                                            <div className='col-xl-3 col-lg-4 col-md-6' key={index}>
                                                                  <Parallax speed={index % 2 === 0 ? 0 : 8}>
                                                                        <div className={`card-qw ${getRandomCardClass()}`} onClick={() => handleOpenModal(item)} style={{ cursor: 'pointer' }}>
                                                                              <div className='card-header'>
                                                                                    <img src={item.imageUrl} alt={item.name} />
                                                                              </div>
                                                                              <div className='card-body'>
                                                                                    <h5>{item.name}</h5>
                                                                                    <p>{item.description}</p>
                                                                              </div>
                                                                        </div>
                                                                  </Parallax>
                                                            </div>
                                                      ))}
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </ParallaxProvider>
                  </section>


                  {selectedItem && (
                        <Modal open={open} onClose={handleCloseModal} center
                              classNames={{ modal: "code-modal" }}>
                              <div className='code-content'>
                                    <h4 className='heading-sx'>{selectedItem.name}</h4>
                                    <p>
                                          {selectedItem.description} 
                                          {selectedItem.description_1} 
                                          {selectedItem.Link
                                          ? <Link target='_blank' to={selectedItem.Link}>{selectedItem.Link.slice(0, 34)}</Link>
                                          : "" }
                                    </p>
                                    <div className='__code'>

                                          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                                <button className='btn button-copy' onClick={handleCopyCode} type='button'> <ContentCopy /></button>
                                                <code>{selectedItem.code}</code>
                                          </pre>
                                         
                                    </div>
                              </div>
                        </Modal>
                  )}

            </>
      );
}

export default CodeInfo;
