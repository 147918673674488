import React, { useEffect, useState } from 'react';
import toasted from '../Config/toast';
import CodeInfo from './Ai/CodeInfo';
import Loader from '../Config/Loader';
import { Link } from 'react-router-dom';
import avtar from '../Assets/Image/avtar.png';


const Coding = () => {

      const [time, setTime] = useState(new Date());
      const [inputValue, setInputValue] = useState('');
      
      useEffect(() => {
            const timer = setInterval(() => {
                  setTime(new Date());
            }, 1000);

            return () => clearInterval(timer);
      }, []);

      const formatTime = (date: Date): string => {
            const hours = (date.getHours() % 12 || 12).toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}&${minutes}`;
      };


      const codepassword = `${formatTime(time)}?code`; /* code section password */


      const [isLocked, setIsLocked] = useState<boolean>(() => {
            // Check if the user is already logged in from localStorage
            const storedLockStatus = localStorage.getItem('isLocked');
            return storedLockStatus === 'false' ? false : true;
      });

      const [attemptCount, setAttemptCount] = useState<number>(() => {
            // Get the stored attempt count from localStorage
            const storedAttempts = localStorage.getItem('attemptCount');
            return storedAttempts ? parseInt(storedAttempts, 10) : 0;
      });

      const [isInputDisabled, setIsInputDisabled] = useState<boolean>(() => {

            const disableUntil = localStorage.getItem('disableUntil');
            const currentTime = Date.now();
            return disableUntil ? currentTime < parseInt(disableUntil, 10) : false;
      });

      const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (inputValue === codepassword) {
                  setIsLocked(false);
                  localStorage.setItem('isLocked', 'false'); 
                  toasted.success('Correct Code Password');
            } else {
                  const newAttemptCount = attemptCount + 1;
                  setAttemptCount(newAttemptCount);
                  toasted.error('Incorrect Code Password');

                  // Save the attempt count to localStorage
                  localStorage.setItem('attemptCount', newAttemptCount.toString());

                  // If three attempts are reached, disable the input
                  if (newAttemptCount >= 3) {
                        setIsInputDisabled(true);
                        const disableUntil = Date.now() + 60000; // 1 minute from now
                        localStorage.setItem('disableUntil', disableUntil.toString());
                  }
            }
      };

      useEffect(() => {
            if (attemptCount >= 3) {
                  setIsInputDisabled(true);
                  const disableUntil = localStorage.getItem('disableUntil');
                  if (disableUntil) {
                        const timeout = parseInt(disableUntil, 10) - Date.now();
                        if (timeout > 0) {
                              // Set a timeout for remaining time to re-enable input
                              const timer = setTimeout(() => {
                                    setAttemptCount(0);
                                    setIsInputDisabled(false);
                                    localStorage.removeItem('attemptCount');
                                    localStorage.removeItem('disableUntil');
                              }, timeout);

                              // Clean up the timer if the component unmounts
                              return () => clearTimeout(timer);
                        } else {
                              // If the timeout has already passed, reset the input
                              setAttemptCount(0);
                              setIsInputDisabled(false);
                              localStorage.removeItem('attemptCount');
                              localStorage.removeItem('disableUntil');
                        }
                  }
            }
      }, [attemptCount]);

      

      
      return (
            <>

                  {isLocked ? (
                        <>
                              <Loader timer={2000} />

                              <div className="phone-lock">
                                    <div className="lock-screen">
                                          <div className='__logos'>
                                                <img src={avtar} alt='logo-avtar' />
                                          </div>
                                          <form onSubmit={handleSubmit}>
                                          {/* ❤️ */}
                                                <input type="text" value={inputValue} onChange={(e) => { setInputValue(e.target.value) }} placeholder=" Enter lock code" className="lock-input" disabled={isInputDisabled} />
                                                <button type='submit' className="submit-btn" disabled={isInputDisabled}> Unlock </button>
                                          </form>

                                          <Link to="/" className="btn btn-common text-center">
                                                Back To home
                                          </Link>

                                    </div>
                              </div>

                        </>

                  ) : (
                        
                        <CodeInfo />
                  )}
            </>
      );
};

export default Coding;
