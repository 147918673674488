import React, { useEffect, useState } from 'react';

const Loader = (props: any) => {
     const [loading, setLoading] = useState<boolean>(true);

     useEffect(() => {
          const fetchData = async () => {
               try {
                    document.body.classList.add('Loader-start');
                    await new Promise(resolve => setTimeout(resolve, props.timer));

                    setTimeout(() => {
                         setLoading(false);
                         document.body.classList.remove('Loader-start');
                    }, 1000); // Match this to the duration of the wave-out animation
               } catch (error) {
                    console.error('Error fetching data', error);
               }
          };

          fetchData();
     }, [props.timer]);

     return (
          <>
               {loading && (
                    <div className="__loader">
                         <div className='__content'>
                              <svg width="16px" height="12px">
                                   <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                                   <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                              </svg>
                              <div className='__txt color-txt'>
                                   <p>ॐ कृष्णाय वासुदेवाय हरये परमात्मने प्रणतः क्लेशनाशाय गोविंदाय नमो नमः</p>
                                   <h6>...</h6>
                              </div>
                         </div>
                    </div>
               )}
          </>
     );
};

export default Loader;
